<template id="calendarActivity">
    <span>
        <ProgressBar v-if="getCargando" mode="indeterminate" style="height: .5em; margin-top: -17px; width: 101%;" />

        <h4 class="text-black">GESTION PAGINA MENSUAL COLABORADOR</h4>

        <div class="card ml-3 mr-3">
                <div class="field">
                    <label for="contractdropdown" class="p-sr-only">Colaborador</label>
                    <div class="field">

                        <autoComplete v-model="selectedColaborator" field="first_name1" :suggestions="filteredDriversMultiple" @complete="searchDriversMultiple($event)" placeholder="Busque conductor"></autoComplete>

                        <Dropdown v-model="selectedMonth" :options="monthNames" optionLabel="Nombre" :filter="false" placeholder="Seleccione Mes *" :showClear="true">
                            <template #value="slotProps">
                                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                      <span>
                                        {{slotProps.value.name}}
                                      </span>
                                  </div>
                                  <span v-else>
                                      {{slotProps.placeholder}}
                                  </span>
                            </template>
                            <template #option="slotProps">
                                  <div class="p-dropdown-supervisors-option">
                                    <span>
                                          {{slotProps.option.name}}</span>
                                  </div>
                            </template>
                        </Dropdown>
                        <Dropdown id="asscewfddsd" v-model="selectYear" :options="years" :filter="false" placeholder="Seleccione año *" :showClear="true">
                              <template #value="slotProps">
                                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                      <span>
                                        {{slotProps.value}}
                                      </span>
                                  </div>
                                  <span v-else>
                                      {{slotProps.placeholder}}
                                  </span>
                              </template>
                              <template #option="slotProps">
                              <div class="p-dropdown-supervisors-option">
                                <span>
                                      {{slotProps.option}}</span>
                              </div>
                            </template>
                        </Dropdown>

                        <buttonp class="w-8rem p-button-warning ml-2" icon="pi pi-search" label="BUSCAR" @click.prevent="getReport()"/>
                         
                    </div>
                </div>
               
                <!--h4 v-if="colaboratorDetail.length > 0" class="text-black">Totales documentos evaluados: {{total_report.total_eval}}</h4-->
        </div>

        
        
        <div v-if="colaboratorDetail.length > 0" >
            <div class="card ml-3 mr-3">
              <Dropdown v-model="selectedContract" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                  <template #value="slotProps">
                        <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                            <span>
                              {{slotProps.value.name}}
                            </span>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                  </template>
                  <template #option="slotProps">
                        <div class="p-dropdown-supervisors-option">
                          <span>
                                {{slotProps.option.name}}</span>
                        </div>
                  </template>
              </Dropdown>

            <Dropdown v-model="selectedType" :options="attendancetTypes" optionLabel="Nombre" :filter="false" placeholder="Seleccione Tipo de asistencia *" :showClear="true">
                <template #value="slotProps">
                      <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                          <span>
                            {{slotProps.value.name}}
                          </span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                </template>
                <template #option="slotProps">
                      <div class="p-dropdown-supervisors-option">
                        <span>
                              {{slotProps.option.name}}</span>
                      </div>
                </template>
            </Dropdown>

            <buttonp label="Agregar" class="ml-1 p-button-sm" icon="pi pi-plus" v-tooltip.left="'Agregar nueva asistencia'" @click="addEmptyAttendance()" autofocus :disabled="false"/>

            
        </div>
            <div class="card ml-6 mr-6">

                <h4 class="text-black">TOTALES <span v-if="selectedColaborator.first_name1">{{selectedColaborator.first_name1}}</span> 
                </h4>

                <h4 class="text-black">Estado Pagina: <span class="text-green"  v-if="currentPage.status === 'COMPLETE'">COMPLETA</span>
                <span class="text-red"  v-if="currentPage.status === 'INCOMPLETE'">INCOMPLETA</span> 
                </h4>

                <!--div v-if="colaboratorDetail.length > 0" class="grid text-black">
                    <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Total '" class="p-button-sm p-button-warning p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'total'" autofocus/></div>

                    <div  class="col-12 md:col-6 lg:col-2"><buttonp :label="'Total:'" class="p-button-sm p-button-grey p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'Total.'" autofocus/></div>
                </div-->

                <h5 class="text-black">{{colaboratorDetail.length}} Dias Creados.</h5>

                <span v-if="totals !== undefined && totals !== null">
                  <h5 class="text-black">{{totals.dias_eval}} Dias Evaluados.</h5>
                  <h5 class="text-black">{{totals.h_totales}} Horas totales ingresadas.</h5>
                  <h5 class="text-black">{{totals.h_prom_diario}} Horas ingresadas promedio diario.</h5>
                  <h5 class="text-black">{{totals.h_laborales}} Horas Laborales.</h5>
                  <h5 class="text-black">{{totals.h_no_laborales}} Horas No laborales.</h5>
                </span>
                



                


            </div>
            <ConfirmDialog></ConfirmDialog>
                    <DataTable id="tableDetailColaborador" :value="colaboratorDetail" responsiveLayout="scroll" v-model:selection="selectedDetailFromReport">
                      <Column selectionMode="single" headerStyle="width: 3em"></Column>
                        <Column  header="Fecha">
                            <template #body="slotProps">
                                <span>{{formatDate(slotProps.data.date)}}</span>
                            </template>
                        </Column>
                        
                        <Column  header="Entrada">
                            <template #body="slotProps">
                                <span v-if="selectedDetailFromReport != null">
                                  <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                          <div class="p-inputgroup">
                                              <span class="p-inputgroup-addon">
                                                  <Checkbox v-model="sig_dia_1" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                              </span>
                                              <InputText type="text" :id="slotProps.index" aria-describedby="end_date_2-help" v-model="new_starttime" style="width: 120px;"/>
                                          </div>
                                  </span>
                                  <span v-else>
                                    <span v-if="slotProps.data.details[0].start_time != null">
                                        {{getOnlyTime(slotProps.data.details[0].start_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>
                                </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[0].start_time != null">
                                        {{getOnlyTime(slotProps.data.details[0].start_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>
                                

                            </template>
                        </Column>
                        <Column header="Salida">
                             <template #body="slotProps">
                               <span v-if="selectedDetailFromReport != null">
                                  <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                          <div class="p-inputgroup">
                                              <span class="p-inputgroup-addon">
                                                  <Checkbox v-model="sig_dia_2" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                              </span>
                                              <InputText type="text" aria-describedby="end_date_2-help" v-model="new_endtime" style="width: 120px;"/>
                                          </div>
                                  </span>
                                   <span v-else>
                                    <span v-if="slotProps.data.details[0].end_time != null">
                                        {{getOnlyTime(slotProps.data.details[0].end_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>
                                </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[0].end_time != null">
                                        {{getOnlyTime(slotProps.data.details[0].end_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>
                            </template>
                        </Column>
                        <Column  header="Horario Libre">
                          <template #body="slotProps">
                                <span v-if="selectedDetailFromReport != null">
                                        <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                          <Checkbox v-model="is_free_1" :binary="true" v-tooltip.left="'Indicar que horario es libre'"/>
                                        </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[0].attendance_type !== null">{{slotProps.data.details[0].attendance_type.name}}</span>
                                    <span v-else>Indeterminado</span>
                                  </span>
                                </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[0].attendance_type !== null">{{slotProps.data.details[0].attendance_type.name}}</span>
                                    <span v-else>Indeterminado</span>
                                  </span>
                          </template>
                        </Column>
                        <Column  header="Entrada">
                            <template #body="slotProps">
                                
                                <span v-if="selectedDetailFromReport != null">
                                  <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <Checkbox v-model="sig_dia_3" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                            </span>
                                            <InputText type="text" aria-describedby="end_date_2-help" v-model="new_starttime2" style="width: 70px;"/>
                                        </div>
                                  </span>
                                  <span v-else>
                                    <span v-if="slotProps.data.details[1].start_time != null">
                                        {{getOnlyTime(slotProps.data.details[1].start_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>

                                </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[1].start_time != null">
                                        {{getOnlyTime(slotProps.data.details[1].start_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>

                            </template>
                        </Column>
                        <Column header="Salida">
                             <template #body="slotProps">
                                <span v-if="selectedDetailFromReport != null">
                                  <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                          <div class="p-inputgroup">
                                              <span class="p-inputgroup-addon">
                                                  <Checkbox v-model="sig_dia_4" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                              </span>
                                              <InputText type="text" aria-describedby="end_date_2-help" v-model="new_endtime2" style="width: 70px;"/>
                                          </div>
                                  </span>
                                  <span v-else>
                                    <span v-if="slotProps.data.details[1].end_time != null">
                                        {{getOnlyTime(slotProps.data.details[1].end_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                  </span>
                                  
                                </span>
                                <span v-else>
                                  
                                    <span v-if="slotProps.data.details[1].end_time != null">
                                        {{getOnlyTime(slotProps.data.details[1].end_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>

                            </template>
                        </Column>
                        <Column  header="Horario Libre">
                          <template #body="slotProps">
                                <span v-if="selectedDetailFromReport != null">
                                        <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                          <Checkbox v-model="is_free_2" :binary="true" v-tooltip.left="'Indicar que horario es libre'"/>
                                        </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[1].attendance_type !== null">{{slotProps.data.details[1].attendance_type.name}}</span>
                                    <span v-else>Indeterminado</span>
                                  </span>
                                </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[1].attendance_type !== null">{{slotProps.data.details[1].attendance_type.name}}</span>
                                    <span v-else>Indeterminado</span>
                                  </span>
                          </template>
                        </Column>
                        <!--Column  header="Contrato">
                            <template #body="slotProps">

                                <span v-if="selectedDetailFromReport != null">
                                <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                    
                                        <Dropdown v-model="selectedContract1" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                                    <template #value="slotProps">
                                          <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                              <span>
                                                {{slotProps.value.name}}
                                              </span>
                                          </div>
                                          <span v-else>
                                              {{slotProps.placeholder}}
                                          </span>
                                    </template>
                                    <template #option="slotProps">
                                          <div class="p-dropdown-supervisors-option">
                                            <span>
                                                  {{slotProps.option.name}}</span>
                                          </div>
                                    </template>
                                </Dropdown>
                                </span>
                                  <span v-else>{{slotProps.data.details[0].contract.name}} - </span>
                              </span>

                              <span v-else>{{slotProps.data.details[0].contract.name}} - </span>

                              <span v-if="selectedDetailFromReport != null">
                                <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                        <Dropdown v-model="selectedContract2" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                                    <template #value="slotProps">
                                          <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                              <span>
                                                {{slotProps.value.name}}
                                              </span>
                                          </div>
                                          <span v-else>
                                              {{slotProps.placeholder}}
                                          </span>
                                    </template>
                                    <template #option="slotProps">
                                          <div class="p-dropdown-supervisors-option">
                                            <span>
                                                  {{slotProps.option.name}}</span>
                                          </div>
                                    </template>
                                </Dropdown>
                                </span>
                                 <span v-else>{{slotProps.data.details[1].contract.name}}</span>
                              </span>

                              <span v-else>{{slotProps.data.details[1].contract.name}}</span>

                            </template>
                        </Column>
                        <Column  header="Tipo Asistencia">
                            <template #body="slotProps">


                              <span v-if="selectedDetailFromReport != null">
                                <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                    

                                    <Dropdown v-model="selectedType1" :options="attendancetTypes" optionLabel="Nombre" :filter="false" placeholder="Tipo asistencia *" :showClear="true">
                                        <template #value="slotProps">
                                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                                  <span>
                                                    {{slotProps.value.name}}
                                                  </span>
                                              </div>
                                              <span v-else>
                                                  {{slotProps.placeholder}}
                                              </span>
                                        </template>
                                        <template #option="slotProps">
                                              <div class="p-dropdown-supervisors-option">
                                                <span>
                                                      {{slotProps.option.name}}</span>
                                              </div>
                                        </template>
                                    </Dropdown>

                                </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[0].attendance_type">
                                    {{slotProps.data.details[0].attendance_type.name}} -
                                  </span>
                                  <span v-else>Sin tipo -</span>
                                </span>
                          </span>
                          <span v-else>
                              <span v-if="slotProps.data.details[0].attendance_type">
                                  {{slotProps.data.details[0].attendance_type.name}} -
                                </span>
                                <span v-else>Sin tipo - </span>
                          </span>

                           <span v-if="selectedDetailFromReport != null">
                                <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                    

                                    <Dropdown v-model="selectedType2" :options="attendancetTypes" optionLabel="Nombre" :filter="false" placeholder="Tipo asistencia *" :showClear="true">
                                        <template #value="slotProps">
                                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                                  <span>
                                                    {{slotProps.value.name}}
                                                  </span>
                                              </div>
                                              <span v-else>
                                                  {{slotProps.placeholder}}
                                              </span>
                                        </template>
                                        <template #option="slotProps">
                                              <div class="p-dropdown-supervisors-option">
                                                <span>
                                                      {{slotProps.option.name}}</span>
                                              </div>
                                        </template>
                                    </Dropdown>
                            </span>
                            <span v-else>
                              <span v-if="slotProps.data.details[1].attendance_type">
                                  {{slotProps.data.details[1].attendance_type.name}}
                                </span>
                                <span v-else>Sin tipo</span>
                          </span>
                          </span>
                          <span v-else>
                              <span v-if="slotProps.data.details[1].attendance_type">
                                  {{slotProps.data.details[1].attendance_type.name}}
                                </span>
                                <span v-else>Sin tipo</span>
                          </span>

                                
                            </template>
                        </Column!-->
                        <Column header="subtotal">
                             
                             <template #body="slotProps">
                                    <span v-if="selectedDetailFromReport != null && selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                        Indicador Suma tiempo: {{getTotalRow(calculateIngreso(slotProps.data.date, new_starttime, new_endtime,0), calculateIngreso(slotProps.data.date, new_starttime2, new_endtime2,1))}}
                                        <br />
                                    </span>
                                    <span v-else>
                                        {{ getTotalRow(calculateSubtotal(slotProps.data.details[0].start_time, slotProps.data.details[0].end_time), calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time))}}
                                    </span>
                                    
                                    <buttonp @click="editGoTwo(slotProps.data.details[0], slotProps.data.date ,0, slotProps.data.details[1], slotProps.data.date ,1, slotProps.index)" v-if="selectedDetailFromReport != null && selectedDetailFromReport.attendance_id == slotProps.data.attendance_id" icon="pi pi-save" class="p-button-success" :label="'Guardar'"/>
                                 
                            </template>
                        </Column>

                        <!--Column selectionMode="single" headerStyle="width: 3em"></Column-->
                        <!--Column  header="Fecha">
                            <template #body="slotProps">
                                <span>{{slotProps.data.date}}</span>
                            </template>
                        </Column>
                        <Column  header="Entrada">
                            <template #body="slotProps">
                              <span v-if="selectedDetailFromReport != null">
                                  <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <Checkbox v-model="sig_dia_3" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                            </span>
                                            <InputText type="text" aria-describedby="end_date_2-help" v-model="new_starttime2" style="width: 70px;"/>
                                        </div>
                                  </span>
                                </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[1].start_time != null">
                                        {{getOnlyTime(slotProps.data.details[1].start_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>
                            </template>
                        </Column>
                        <Column header="Salida">
                             <template #body="slotProps">
                              <span v-if="selectedDetailFromReport != null">
                                  <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                          <div class="p-inputgroup">
                                              <span class="p-inputgroup-addon">
                                                  <Checkbox v-model="sig_dia_4" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                              </span>
                                              <InputText type="text" aria-describedby="end_date_2-help" v-model="new_endtime2" style="width: 70px;"/>
                                          </div>
                                  </span>
                                </span>
                                <span v-else>
                                    <span v-if="slotProps.data.details[1].end_time != null">
                                        {{getOnlyTime(slotProps.data.details[1].end_time)}}
                                    </span>
                                    <span v-else>
                                        Sin hora
                                    </span>
                                </span>
                            </template>
                        </Column>
                        
                        <Column  header="Contrato">
                            <template #body="slotProps">
                                <span v-if="selectedDetailFromReport != null">
                                <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                        <Dropdown v-model="selectedContract2" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                                    <template #value="slotProps">
                                          <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                              <span>
                                                {{slotProps.value.name}}
                                              </span>
                                          </div>
                                          <span v-else>
                                              {{slotProps.placeholder}}
                                          </span>
                                    </template>
                                    <template #option="slotProps">
                                          <div class="p-dropdown-supervisors-option">
                                            <span>
                                                  {{slotProps.option.name}}</span>
                                          </div>
                                    </template>
                                </Dropdown>
                                </span>
                                 <span v-else>{{slotProps.data.details[1].contract.name}}</span>
                              </span>

                              <span v-else>{{slotProps.data.details[1].contract.name}}</span>



                            </template>
                        </Column>
                        <Column  header="Tipo Asistencia">
                            <template #body="slotProps">
                                

                                <span v-if="selectedDetailFromReport != null">
                                <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                    

                                    <Dropdown v-model="selectedType2" :options="attendancetTypes" optionLabel="Nombre" :filter="false" placeholder="Tipo asistencia *" :showClear="true">
                                        <template #value="slotProps">
                                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                                  <span>
                                                    {{slotProps.value.name}}
                                                  </span>
                                              </div>
                                              <span v-else>
                                                  {{slotProps.placeholder}}
                                              </span>
                                        </template>
                                        <template #option="slotProps">
                                              <div class="p-dropdown-supervisors-option">
                                                <span>
                                                      {{slotProps.option.name}}</span>
                                              </div>
                                        </template>
                                    </Dropdown>


                            </span>
                            <span v-else>
                              <span v-if="slotProps.data.details[1].attendance_type">
                                  {{slotProps.data.details[1].attendance_type.name}}
                                </span>
                                <span v-else>Sin tipo</span>
                          </span>
                          </span>
                          <span v-else>
                              <span v-if="slotProps.data.details[1].attendance_type">
                                  {{slotProps.data.details[1].attendance_type.name}}
                                </span>
                                <span v-else>Sin tipo</span>
                          </span>
                                
                            </template>
                        </Column>
                        <Column header="Subtotal">
                             <template #body="slotProps">
                              <span v-if="selectedDetailFromReport != null">
                                  <span v-if="selectedDetailFromReport.attendance_id == slotProps.data.attendance_id">
                                  <buttonp v-if="selectedDetailFromReport != null && selectedDetailFromReport.attendance_id == slotProps.data.attendance_id" icon="pi pi-check" class="p-button-success" :label="calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time).toString()" @click="editGo(slotProps.data.details[1], slotProps.data.date ,1)"/>
                                </span>
                                <span v-else>
                                        {{calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time).toString()}}
                                  </span>
                              </span>
                                  <span v-else>
                                        {{calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time).toString()}}
                                  </span>
                            </template>
                        </Column>
                        <Column header="Subtotal">
                             <template #body="slotProps">
                                <span>{{ getTotalRow(calculateSubtotal(slotProps.data.details[0].start_time, slotProps.data.details[0].end_time), calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time))}}</span>
                            </template>
                        </Column-->
                        <Column header="Acciones" style="width: 200px;">
                            <template #body="slotProps">

                                <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-pencil" v-tooltip.left="'Editar Detalle'" @click="openModalEdit(slotProps.data)" autofocus :disabled="!(selectedDetailFromReport != null && selectedDetailFromReport.attendance_id == slotProps.data.attendance_id)"/>

                                <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-trash" v-tooltip.left="'Borrar detalle asistencia'" @click="deleteAttendance(slotProps.data)" autofocus :disabled="slotProps.index !== colaboratorDetail.length-1"/>

                            </template>
                        </Column>
            </DataTable>

            <br /><br />

             <buttonp v-if="currentPage.status === 'INCOMPLETE'" class="w-12rem p-button-warning ml-2" icon="pi pi-file" label="COMPLETAR HOJA DE ASISTENCIA" @click.prevent="completePage()"/>


             <div v-if="currentPage.status === 'COMPLETE'">
                    <!--span v-if="platform !== 'browser'">
                        <a href="#" class="button is-medium is-fullwidth is-warning is-hidden-desktop" @click.prevent="takeImage()">
                            <span>Subir Sacando Foto {{platform}}</span>
                        </a>
                    </span-->
                    <div class="field mt-4">
                      <h5 class="text-black">Archivo Pagina</h5>
                      <FileUpload mode="basic" name="demo[]" url="./upload" chooseLabel="Ingrese archivo de pagina" :customUpload="true" @uploader="myUploader"/>
                    </div>

                </div>

                <div v-if="currentPage.status === 'COMPLETE' && currentPage.img_url !== null">
                    
                    <a :href="static_url+currentPage.img_url" download target="_blank">Bajar Archivo 
                        <i class="mdi mdi-download"  style="font-size:16px"></i>
                      </a>

                    <br />
                    <br />
                    <buttonp class="w-12rem p-button-warning ml-2" icon="pi pi-file" label="Borrar Acrchivo" @click.prevent="deletePageFile()"/>

                </div>

                <br /><br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /><br />
            
        </div>
        <div v-else>
            <h2>Sin Resultados</h2>

            <Dropdown v-model="selectedContract" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                <template #value="slotProps">
                      <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                          <span>
                            {{slotProps.value.name}}
                          </span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                </template>
                <template #option="slotProps">
                      <div class="p-dropdown-supervisors-option">
                        <span>
                              {{slotProps.option.name}}</span>
                      </div>
                </template>
            </Dropdown>

            <Dropdown v-model="selectedType" :options="attendancetTypes" optionLabel="Nombre" :filter="false" placeholder="Seleccione Tipo de asistencia *" :showClear="true">
                <template #value="slotProps">
                      <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                          <span>
                            {{slotProps.value.name}}
                          </span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                </template>
                <template #option="slotProps">
                      <div class="p-dropdown-supervisors-option">
                        <span>
                              {{slotProps.option.name}}</span>
                      </div>
                </template>
            </Dropdown>

            <buttonp v-if="noEncontro" label="Detalle" class="ml-1 p-button-sm" icon="pi pi-plus" v-tooltip.left="'Agregar nueva asistencia'" @click="addAttendance()" autofocus :disabled="false"/>

            <buttonp v-if="noEncontro" label="Mes Completo" class="ml-1 p-button-sm" icon="pi pi-plus" v-tooltip.left="'Agregar nueva asistencia'" @click="addAllAttendanceMonth()" autofocus :disabled="false"/>

        </div>

        <Dialog :header="'Edicion detalle'" v-model:visible="displayDetail" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">

                <div class="md-min-width card m-5">
                    <h4 class="text-black">Primera Parte Jornada</h4>
                    <h5 class="text-black">Entrada:</h5>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <Checkbox v-model="sig_dia_1" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                        </span>
                        <InputText type="text" aria-describedby="end_date_2-help" v-model="new_starttime" style="width: 120px;"/>
                    </div>
                    <h5 class="text-black">Salida:</h5>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                                                  <Checkbox v-model="sig_dia_2" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                              </span>
                                              <InputText type="text" aria-describedby="end_date_2-help" v-model="new_endtime" style="width: 120px;"/>
                    </div>
                    <h5 class="text-black">Contrato:</h5>
                    <Dropdown v-model="selectedContract1" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                                    <template #value="slotProps">
                                          <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                              <span>
                                                {{slotProps.value.name}}
                                              </span>
                                          </div>
                                          <span v-else>
                                              {{slotProps.placeholder}}
                                          </span>
                                    </template>
                                    <template #option="slotProps">
                                          <div class="p-dropdown-supervisors-option">
                                            <span>
                                                  {{slotProps.option.name}}</span>
                                          </div>
                                    </template>
                                </Dropdown>
                    <h5 class="text-black">Tipo Asistencia:</h5>
                    <Dropdown v-model="selectedType1" :options="attendancetTypes" optionLabel="Nombre" :filter="false" placeholder="Tipo asistencia *" :showClear="true">
                                        <template #value="slotProps">
                                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                                  <span>
                                                    {{slotProps.value.name}}
                                                  </span>
                                              </div>
                                              <span v-else>
                                                  {{slotProps.placeholder}}
                                              </span>
                                        </template>
                                        <template #option="slotProps">
                                              <div class="p-dropdown-supervisors-option">
                                                <span>
                                                      {{slotProps.option.name}}</span>
                                              </div>
                                        </template>
                                    </Dropdown>

                    <br /><br />
                    <h4 class="text-black">Seguda Parte Jornada</h4>
                    <h5 class="text-black">Entrada:</h5>
                    <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <Checkbox v-model="sig_dia_3" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                            </span>
                                            <InputText type="text" aria-describedby="end_date_2-help" v-model="new_starttime2" style="width: 70px;"/>
                                        </div>
                    <h5 class="text-black">Salida:</h5>
                    <div class="p-inputgroup">
                                              <span class="p-inputgroup-addon">
                                                  <Checkbox v-model="sig_dia_4" :binary="true" v-tooltip.left="'Indicar hora si es del siguiente dia'"/>
                                              </span>
                                              <InputText type="text" aria-describedby="end_date_2-help" v-model="new_endtime2" style="width: 70px;"/>
                                          </div>
                    <h5 class="text-black">Contrato:</h5>
                     <Dropdown v-model="selectedContract2" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                                    <template #value="slotProps">
                                          <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                              <span>
                                                {{slotProps.value.name}}
                                              </span>
                                          </div>
                                          <span v-else>
                                              {{slotProps.placeholder}}
                                          </span>
                                    </template>
                                    <template #option="slotProps">
                                          <div class="p-dropdown-supervisors-option">
                                            <span>
                                                  {{slotProps.option.name}}</span>
                                          </div>
                                    </template>
                                </Dropdown>
                    <h5 class="text-black">Tipo Asistencia:</h5>
                    <Dropdown v-model="selectedType2" :options="attendancetTypes" optionLabel="Nombre" :filter="false" placeholder="Tipo asistencia *" :showClear="true">
                                        <template #value="slotProps">
                                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                                  <span>
                                                    {{slotProps.value.name}}
                                                  </span>
                                              </div>
                                              <span v-else>
                                                  {{slotProps.placeholder}}
                                              </span>
                                        </template>
                                        <template #option="slotProps">
                                              <div class="p-dropdown-supervisors-option">
                                                <span>
                                                      {{slotProps.option.name}}</span>
                                              </div>
                                        </template>
                                    </Dropdown>

                </div>

            <template #footer>
                <buttonp label="Guardar" class="ml-1 p-button-sm" icon="pi pi-pencil" @click="editGoTwo(selectedDetailFromReport.details[0], selectedDetailFromReport.date ,0, selectedDetailFromReport.details[1], selectedDetailFromReport.date ,1, null)" autofocus/>
                <buttonp label="Cerrar" icon="pi pi-check" @click="displayDetail=false;" autofocus />
            </template>
        </Dialog>
        
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import EmployeeService from '@/service/EmployeeService';
    import AttendanceService from '@/service/AttendanceService';
    //import Statustag from '@/components/StatusTag'
    import moment from "moment";
    import ContractService from '@/service/ContractService';
    import UploadFileService from '@/service/UploadFileService';


    let AdminDocumentation = {};
    (function () {
        this.name = 'admin-';

        this.created = function () {
            this.getAllColaborator();
            //this.getAllDocumentation();
            //this.getAllStatus();
            this.getAllContracts();
            this.getAllAttendanceType();

            if(this.searchemployee == "[object Object]" || this.searchMonth == "[object Object]" || this.searchYear == "[object Object]"){
                this.selectedColaborator = null; 
                this.selectYear = null; 
                this.selectedMonth = null; 
            }else{
                if((this.searchemployee !== undefined || this.searchemployee !== null) && (this.searchYear !== undefined || this.searchYear !== undefined) && this.searchMonth !== undefined || this.searchMonth !== undefined){
                        this.selectedColaborator = this.searchemployee;
                        this.selectYear = this.searchYear;
                        this.selectedMonth = this.searchMonth;
                        this.getReport();
                }
            }
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                cargando: false,
                allStatus: [],
                contracts: [],
                selectedContract: null,
                colaboratorDetail: [],
                total_report: [],
                selectedDetails: [],
                displayDetailDocs: false,
                selectedColaborator: null,
                filteredDriversMultiple: [],
                all_colaboratrs: [],
                documentations: [],
                selectedDetailFromReport: null,
                monthNames: [{id:1, name: 'Enero'}, {id:2, name: 'Febrero'}, {id:3, name: 'Marzo'}, {id:4, name: 'Abril'}, {id:5, name: 'Mayo'}, {id:6, name: 'Junio'}, {id:7, name: 'Julio'}, {id:8, name: 'Agosto'}, {id:9, name: 'Septiembre'}, {id:10, name: 'Octubre'}, {id:11, name: 'Noviembre'}, {id:12, name: 'Deciembre'}],
                selectedMonth: null,
                years:[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027],
                selectYear: null,
                noEncontro: false,
                displayDetail: false,
                selectedDetail: null,
                attandance_static_url: process.env.VUE_APP_STATIC_ATTENDANCE_IMG,
                static_url: process.env.VUE_APP_STATIC,
                rutaArchivoSubido: null,
                selectedType: null,
                attendancetTypes: [],
                new_endtime: null,
                new_starttime: null,
                new_endtime2: null,
                new_starttime2: null,
                sig_dia_1: false,
                sig_dia_2: false,
                sig_dia_3: false,
                sig_dia_4: false,
                selectedContract1: null,
                selectedContract2: null,
                selectedType1: null,
                selectedType2: null,
                totals: {'dias_eval': 0, 'h_laborales': 0, 'h_no_laborales': 0, 'h_prom_diario' : 0, 'h_totales' : 0 },
                savedIndex: null,
                is_free_1: false,
                is_free_2: false,
            }
        };
        this.methods = {
          openModalEdit(selectedRow){
            this.selectedDetailFromReport = selectedRow;
            this.displayDetail = true;
            //this.preparetoEdit();
          },
          editGoTwo(detail1, attendance_date1, position1, detail2, attendance_date2, position2, index){
            
            var op_1 =false;
            var op_2 = false;

            if(this.new_starttime != null && this.new_endtime!= null){
              op_1 = true;
              //this.editGo(detail1, attendance_date1, position1, false);
            }
            if(this.new_starttime2 != null && this.new_endtime2 != null){
              op_2 = true;
              //this.editGo(detail2, attendance_date2, position2, true);
            }

            if(op_1 && op_2){
              this.editGo(detail1, attendance_date1, position1, false);
              this.editGo(detail2, attendance_date2, position2, true);
              this.savedIndex = index;
            }else{
              this.savedIndex = index;
              if(op_1 && !op_2){
                this.editGo(detail1, attendance_date1, position1, true);
              }else{
                if(!op_1 && op_2){
                  this.editGo(detail2, attendance_date2, position2, true);
                }
              }
            }
            
          },
          editGo(detail, attendance_date, position, getReport){

            var s_date = null;
            var e_date = null;

            var se_type_id = null;


            if(position == 0){
              if(this.sig_dia_1){
                s_date = moment(attendance_date, "YYYY-MM-DD").add(1, 'days').format("DD-MM-YYYY") + ' '+ this.new_starttime;
              }else{
                  s_date = moment(attendance_date, "YYYY-MM-DD").format("DD-MM-YYYY")  + ' '+ this.new_starttime
              }

              if(this.sig_dia_2){
                  e_date = moment(attendance_date, "YYYY-MM-DD").add(1, 'days').format("DD-MM-YYYY") + ' '+ this.new_endtime;
              }else{
                  e_date = moment(attendance_date, "YYYY-MM-DD").format("DD-MM-YYYY")  + ' '+ this.new_endtime;
              }


               if(this.selectedType1 != null){
                  if(this.is_free_1 == true){
                    var tipoooo = this.attendancetTypes.find(t => t.name === 'Libre');
                    se_type_id = tipoooo.type_id;
                  }else{
                    var tipOO = this.attendancetTypes.find(t => t.name === 'Laboral');
                    se_type_id = tipOO.type_id;
                  }
              }

            }

            if(position == 1){
              if(this.sig_dia_3){
                s_date = moment(attendance_date, "YYYY-MM-DD").add(1, 'days').format("DD-MM-YYYY") + ' '+ this.new_starttime2;
              }else{
                  s_date = moment(attendance_date, "YYYY-MM-DD").format("DD-MM-YYYY")  + ' ' + this.new_starttime2
              }

              if(this.sig_dia_4){
                  e_date = moment(attendance_date, "YYYY-MM-DD").add(1, 'days').format("DD-MM-YYYY") + ' '+ this.new_endtime2;
              }else{
                  e_date = moment(attendance_date, "YYYY-MM-DD").format("DD-MM-YYYY")  + ' '+ this.new_endtime2;
              }


              if(this.selectedType2 != null){
                  if(this.is_free_2 == true){
                    var tipoo = this.attendancetTypes.find( t => t.name === 'Libre' );
                    se_type_id = tipoo.type_id;
                  }else{
                    var tipO = this.attendancetTypes.find(t => t.name === 'Laboral');
                    se_type_id = tipO.type_id;
                  }
              }

            }

            var subtotal = this.calculateSubtotal(s_date, e_date);

            if(subtotal < 0){
              this.$store.dispatch('form/result', {isError: true, message: 'Salida debe ser mayor a Entrada'});
              return;
              
            }
            
            var edited_detail = {
                'detail_id': detail.detail_id,
                'start_time': s_date,
                'end_time': e_date,
                'contract_id': this.selectedContract1.contract_id,
                'type_id': se_type_id,
            }

            AttendanceService.editdetailFromtable(edited_detail).then((response) => {
                    this.proccessmessageResponse(response);
                    if(getReport == true){
                      this.getReport();
                      this.displayDetail  = false;
                    }
                    //this.selectedDetailFromReport = null;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

          },
          addOneDayToDate(index, detail_index, date){
            return date;
          },
          getOnlyDate(date){
              if(date){
                var stillUtc = moment.utc(date).toDate();
                var local = moment(stillUtc).local().format('DD-MM-YYYY');
                return local;
              }
          },
          getOnlyDate2(date){
              if(date){
                var stillUtc = moment.utc(date, "YYYY-MM-DD HH:mm:ss").subtract(4, "hours").format('YYYY-MM-DD');
                //var local = moment(stillUtc).local()
                return stillUtc;
              }
          },
          getOnlyTime(date){
              
              if(date){
                 var stillUtc = moment.utc(date,"YYYY-MM-DD HH:mm:ss").subtract(4, "hours").format('HH:mm');
                //var stillUtc = moment.utc(date).toDate();
              
                //var local = moment(stillUtc).local().format('HH:mm');
                return stillUtc;
              }
          },
          myUploader(event){
                 this.$store.dispatch('form/submit', true);
                // upload data to the server
                 let result = {isError: false, message: null}
                 var fileList = event.files;
                const formData = new FormData();
                formData.append ('docs', fileList[0]);
                formData.append ('destination', "attandence-page/");
                formData.append ('id', this.currentPage.page_id);
                formData.append ('not_uuid', true);

                UploadFileService.uploadfile(formData).then((response) => {
                    this.proccessmessageResponse(response);
                    this.$store.dispatch('form/submit', false);
                    this.rutaArchivoSubido = response.data.file_path;
                      AttendanceService.updateimg(this.currentPage.page_id, this.rutaArchivoSubido).then((response) => {
                          this.proccessmessageResponse(response);
                          this.$store.dispatch('form/submit', false);
                          this.getReport();
                      }).catch((error) => {
                          console.log(error);
                          this.$store.dispatch('form/submit', false);
                      });
                }).catch((error) => {
                    result.isError = true;
                    if (error.data) {
                        result.message = error.data.message;
                    } else {
                        result.message =  'Ha ocurrido un error interno(uploadfile)'
                    }
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false);
                })
            },
          deletePageFile(){
            this.$store.dispatch('form/submit', true);
                AttendanceService.deleteImgPage(this.currentPage.page_id).then((response) => {
                    this.proccessmessageResponse(response);
                    this.$store.dispatch('form/submit', false);
                    this.getReport();
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
          },
          completePage(){
            this.$store.dispatch('form/submit', true);
             AttendanceService.completePageStatus(this.currentPage.page_id).then((response) => {
                    this.proccessmessageResponse(response);
                    this.$store.dispatch('form/submit', false);
                    this.getReport();
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
          },
            getTotalRow(val,val2){
                return parseFloat(val)+parseFloat(val2);
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY, h:mm:ss');
                  return local;
                }
            },
            formatDate(date){
              var local = moment(date).format('DD-MM-YYYY');
              return local;
            },
            calculateIngreso(date, hr1, hr2, position){
              
               if(hr1 === null || hr2 === null || hr1 === undefined || hr2 === undefined)
                    return 0
                else{
                    if(position == 0){
                        if(this.sig_dia_1){
                          date_1 = moment(date, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                        }

                        if(this.sig_dia_2){
                            date_2 = moment(date, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                        }
                      }

                      if(position == 1){
                        if(this.sig_dia_3){
                          date_1 = moment(date, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                        }

                        if(this.sig_dia_4){
                            date_2 = moment(date, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                        }
                      }

                    var date_1 = date + ' ' +hr1
                    var date_2 = date + ' ' +hr2
                    var stillUtc = moment.utc(date_1, "YYYY-MM-DD HH:mm");
                    var stillUtc_2 = moment.utc(date_2, "YYYY-MM-DD HH:mm");
                    var duration = stillUtc_2.diff(stillUtc);
                    if(duration > 0){
                        duration = Math.floor(duration / 1000);
                        duration= duration * 0.000277778;
                        duration = duration.toFixed(2)
                    }
                    return duration;
                }
            },
            calculateSubtotal(date1, date2){
                if(date1 === null || date2 === null)
                    return 0
                else{
                    var stillUtc = moment.utc(date1);
                    var stillUtc_2 = moment.utc(date2);
                    var duration = stillUtc_2.diff(stillUtc);
                    if(duration > 0){
                        duration = Math.floor(duration / 1000);
                        duration= duration * 0.000277778;
                        duration = duration.toFixed(2)
                    }
                    return duration;
                }
            },
            editDetail(detail){
                this.selectedDetail=detail;
                this.displayDetail= true;
            },
            saveEditDetail(){
                AttendanceService.editDetail(this.selectedDetail).then((response) => {
                    this.proccessmessageResponse(response);
                    if(response.status =='OK')
                        this.displayDetail = false;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            proccessmessageResponse(response){
                var isError = true
                if(response.data.status == 'OK') 
                    isError = false;
                this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
            },
            addAllAttendanceMonth(){
                this.$store.dispatch('form/submit', true)

                if(!this.validate()) return;

                if(this.selectedContract == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Contrato'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if(this.selectedType == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione tipo de asistencia'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                AttendanceService.addAllMonth(this.selectYear, this.selectedMonth.id, this.selectedColaborator.employee_id, this.selectedContract.contract_id, this.selectedType.type_id).then((response) => {
                    this.proccessmessageResponse(response);
                    console.log(response.data.data.detail);
                    this.colaboratorDetail = response.data.data.detail;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },
            addEmptyAttendance(){
                this.$store.dispatch('form/submit', true)
                if(this.selectedContract == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Contrato'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if(this.selectedType == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione tipo de asistencia'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if(!this.validate()) return;
                AttendanceService.addAttendance(this.selectYear, this.selectedMonth.id, this.selectedColaborator.employee_id, this.selectedContract.contract_id, this.selectedType.type_id).then((response) => {
                    this.proccessmessageResponse(response);
                    this.colaboratorDetail = response.data.data.detail;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            addAttendance(){
                this.$store.dispatch('form/submit', true)
                if(this.selectedContract == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Contrato'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if(this.selectedType == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione tipo de asistencia'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if(!this.validate()) return;
                AttendanceService.addOnePage(this.selectYear, this.selectedMonth.id, this.selectedColaborator.employee_id, this.selectedContract.contract_id, this.selectedType.type_id).then((response) => {
                    this.proccessmessageResponse(response);
                    this.colaboratorDetail = response.data.data.detail;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            getAllStatus(){
                this.$store.dispatch('form/submit', true);
            },
            deleteAttendance(attandence) {
                this.$confirm.require({
                    message: '¿Desea borrar detalle?',
                    header: 'Eliminar detalle pagina de asistencia',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        //let result = {isError: false, message: null}
                        this.$store.dispatch('form/reset');
                        this.$store.dispatch('form/submit', true);
                        AttendanceService.deleteDetail(attandence.attendance_id).then((response) => {
                            this.proccessmessageResponse(response);
                            this.$store.dispatch('form/submit', false);
                            this.getReport();
                        }).catch((error) => {
                            console.log(error);
                            this.$store.dispatch('form/submit', false);
                        });
                    },
                    reject: () => {
                        
                    }
                });
            },
            getAllContracts(){
                this.$store.dispatch('form/submit', true);
                ContractService.getByEmployee(this.username).then((response) => {
                    this.contracts = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllAttendanceType(){
                this.$store.dispatch('form/submit', true);
                AttendanceService.getAttendanceType().then((response) => {
                    this.attendancetTypes = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllColaborator(){
                 EmployeeService.getAll().then((response) => {
                        this.all_colaboratrs= response.data;
                        for (var i = 0; i < this.all_colaboratrs.length; i++) {
                            this.all_colaboratrs[i]['first_name1'] = this.all_colaboratrs[i]['first_name1'] +" "+this.all_colaboratrs[i]['first_name2']+' '+this.all_colaboratrs[i]['last_name1'] + ' ' +this.all_colaboratrs[i]['last_name2']+'('+this.all_colaboratrs[i]['card_id'] +'-'+ this.all_colaboratrs[i]['check_digit']+')';
                        }

                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            searchDriversMultiple(event){
                var filteredResultDrivers = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_colaboratrs.length; i++) {
                        if (this.all_colaboratrs[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.all_colaboratrs[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResultDrivers.push(this.all_colaboratrs[i])
                        }
                    }
                    this.filteredDriversMultiple = filteredResultDrivers;
                } else {
                    return [];
                }   
            },
            openDetailDocumentation(id){
                this.$router.push({name: '',
                           params: {
                                documentation_implementation_id: id
                            }
                       });
            },
            validate(){
                 let result = {isError: false, message: null}
                 
                if(this.selectedColaborator == null){
                    result.isError = true;
                    result.message = 'Seleccione Colaborador';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                if(this.selectYear == null){
                    result.isError = true;
                    result.message = 'Seleccione año';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                if(this.selectedMonth == null){
                    result.isError = true;
                    result.message = 'Seleccione mes';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                return true;
            },
            getReport() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}
                this.noEncontro = false;
                if(!this.validate()) return;

                this.$store.dispatch('search/set-employee', this.selectedColaborator);
                this.$store.dispatch('search/set-month', this.selectedMonth);
                this.$store.dispatch('search/set-year', this.selectYear);
                this.colaboratorDetail = [];
                this.total_report = [];
                
                this.cargando = true;
                AttendanceService.getMonthReport(this.selectYear, this.selectedMonth.id, this.selectedColaborator.employee_id)
                    .then(response => {
                    
                    this.currentPage = response.data.data.page;
                    this.colaboratorDetail = response.data.data.detail;

                    this.totals = response.data.data.totals
                    this.total_report = 0;

                    console.log(this.savedIndex);
                    if(this.savedIndex != null){
                      this.selectedDetailFromReport = this.colaboratorDetail[this.savedIndex + 1];

                      setTimeout(() => {
                          var input = (this.savedIndex + 1).toString();
                          var sup_imp = document.getElementById(input);
                          console.log(sup_imp);
                          sup_imp.focus();
                          //window.scrollTo({ top: sup_imp, behavior: 'smooth'});
                      }, 500);

                    }
                          
                    if(response.data.data.detail.length === 0){
                        this.noEncontro = true;
                        result.isError = true;
                        result.message = 'Sin resultados';
                        this.$store.dispatch('form/result', result);
                    }
                    this.$store.dispatch('form/submit', false);
                    this.cargando = false;
                    })
                    .catch(err => {
                      console.log(err);
                      this.cargando = false;
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
            preparetoEdit(){
                      this.new_starttime = null;
                      this.new_endtime = null
                      this.new_starttime1 = null;
                      this.new_endtime2 = null;
                      this.selectedType1 = null;
                      this.selectedType2 = null;
                      this.selectedContract1  = null;
                      this.selectedContract2  = null;
                      this.sig_dia_1 = false;
                      this.sig_dia_2 = false;
                      this.sig_dia_3 = false;
                      this.sig_dia_4 = false;

                      this.is_free_1  = false;
                      this.is_free_2 = false;

                      var start_date = this.getOnlyDate2(this.selectedDetailFromReport.details[0].start_time)
                      var start_time = this.getOnlyTime(this.selectedDetailFromReport.details[0].start_time);

                      console.log(this.selectedDetailFromReport.details[0].end_time);
                      var end_date = this.getOnlyDate2(this.selectedDetailFromReport.details[0].end_time);
                      var end_time = this.getOnlyTime(this.selectedDetailFromReport.details[0].end_time);

                      var start_date2 = this.getOnlyDate2(this.selectedDetailFromReport.details[1].start_time)
                      var start_time2 = this.getOnlyTime(this.selectedDetailFromReport.details[1].start_time);
                      var end_date2 = this.getOnlyDate2(this.selectedDetailFromReport.details[1].end_time);
                      var end_time2 = this.getOnlyTime(this.selectedDetailFromReport.details[1].end_time);

                      if(start_date != this.selectedDetailFromReport.date && start_date != null){
                        this.sig_dia_1 = true;
                      }
                      if(end_date != this.selectedDetailFromReport.date && end_date != null){
                        this.sig_dia_2 = true;
                      }
                      if(start_date2 != this.selectedDetailFromReport.date && start_date2 != null){
                        this.sig_dia_3 = true;
                      }
                      if(end_date2 != this.selectedDetailFromReport.date && end_date2 != null){
                        this.sig_dia_4 = true;
                      }

                      this.new_starttime = start_time;
                      this.new_endtime = end_time
                      this.new_starttime2 = start_time2;
                      this.new_endtime2 = end_time2;
                      this.selectedType1 = this.selectedDetailFromReport.details[0].attendance_type;
                      this.selectedType2 = this.selectedDetailFromReport.details[1].attendance_type;
                      this.selectedContract1  = this.selectedDetailFromReport.details[0].contract;
                      this.selectedContract2  = this.selectedDetailFromReport.details[1].contract;

                      if(this.selectedType1.name == 'Libre'){
                        this.is_free_1 = true;
                      }

                      if(this.selectedType2.name == 'Libre'){
                        this.is_free_2 = true;
                      }

            },
        };
        this.components = {};
        this.computed = {
            getNoEncontro(){
                return this.noEncontro;
            },
            getCargando(){
                return this.cargando;
            },
            ...mapGetters({
                username: 'auth/user',
                searchemployee: 'search/employee',
                searchYear: 'search/year',
                searchMonth: 'search/month',
                groups: 'auth/groups'
            }),
        };
        this.watch = {
            selectedDetailFromReport(newvalue){
                      
                      if(newvalue == null) return;
                      this.preparetoEdit();
              
            }

        };
        this.props = [];
    }).apply(AdminDocumentation);

    export default AdminDocumentation
</script>
