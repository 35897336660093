import axios from "axios";

export default {

    getAttendanceType() {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/getallattendancetype`;
        let data = {};
        return axios.post(url, data)
    },

    getMonthReport(year, month, employee) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/getmonthreport`;
        let data = {year: year, month: month, employee:employee};
        return axios.post(url, data)
    },

    getAllMonthReport(year, month, contract_id) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/allgetmonthreport`;
        let data = {year: year, month: month, contract_id:contract_id};
        return axios.post(url, data)
    },
    
    addOnePage(year, month, employee, contract_id, type_id) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/addemptyone`;
        let data = {year: year, month: month, employee: employee, contract_id: contract_id, type_id:type_id};
        return axios.post(url, data)
    },
    addAllMonth(year, month, employee, contract_id, type_id) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/addallmonth`;
        let data = {year: year, month: month, employee: employee, contract_id: contract_id, type_id:type_id};
        return axios.post(url, data)
    },
    editDetail(data) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/editdetail`;
        return axios.post(url, data)
    },

    editdetailFromtable(data) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/editdetailFromtable`;
        return axios.post(url, data)
    },


    

    deleteDetail(attendance_id) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/deletedetail`;
        let data = {attendance_id: attendance_id};
        return axios.post(url, data)
    },

    addAttendance(year, month, employee, contract_id, type_id) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/addattendance`;
        let data = {year: year, month: month, employee: employee, contract_id: contract_id, type_id:type_id};
        return axios.post(url, data)
    },

    completePageStatus(page_id) {
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/completettendancepage`;
        let data = {page_id: page_id};
        return axios.post(url, data)
    },

    deleteImgPage(page_id){
         const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/deleteimgpage`;
        let data = {page_id: page_id};
        return axios.post(url, data)
    },

    updateimg(page_id, path){
        const url = `${process.env.VUE_APP_API_ATTENDANCE_URL}/updateimgpage`;
        let data = {page_id: page_id, path: path};
        return axios.post(url, data)
    }
}
