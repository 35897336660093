import { createWebHistory, createRouter } from "vue-router";
import Login from '../views/LoginPage';
import Home from '../views/HomePage';
import SelectContract from '../views/SelectContract';
import CollaboratorMonthReport from '../views/CollaboratorMonthReport';
import AllCollaboratorMonthReport from '../views/AllCollaboratorMonthReport';
import AdminCollaborator from '../views/AdminCollaborator';

const routes = [
  {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            module: 'authentication'
        }
    },
    {
        path: '/home',
        alias: ['/home'],
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },
    {
        path: '/select-contract',
        alias: ['/select-contract'],
        name: 'select-contract',
        component: SelectContract,
        meta: {
            requiresAuth: true,
            module: 'contract'
        }
    },
    {
        path: '/admin-collaborator',
        alias: ['/admin-collaborator'],
        name: 'admin-collaborator',
        component: AdminCollaborator,
        meta: {
            requiresAuth: true,
            module: 'admin'
        }
    },
    {
        path: '/collaborator-report',
        alias: ['/collaborator-report'],
        name: 'collaborator-report',
        component: CollaboratorMonthReport,
        meta: {
            requiresAuth: true,
            module: 'admin'
        }
    },
    {
        path: '/all-collaborator-report',
        alias: ['/all-collaborator-report'],
        name: 'all-collaborator-report',
        component: AllCollaboratorMonthReport,
        meta: {
            requiresAuth: true,
            module: 'admin'
        }
    },
    {
        name: 'logout',
        path: '/logout',
        redirect: to => {
            console.log(to);
            return {name: 'login'};
        },
        meta: {
            requiresAuth: false,
            module: 'authentication'
        }
    },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

routes.map((route) => {
    if (!route.meta) route.meta = {};
    if (route.meta.requiresAuth !== false)
        route.meta.requiresAuth = true;
});

export default router
