const state = () => ({
	employee: localStorage.getItem('employee'),
    year: localStorage.getItem('month'),
    month: localStorage.getItem('month'),
});
 
const getters = {
	employee(state) {
        return state.employee
    },
    year(state) {
        return state.year
    },
    month(state) {
        return state.month
    },
};
 
const actions = {
	'set-employee'(context, employee) {
        context.commit('employee', employee);
        localStorage.setItem('employee', employee);
    },
    'set-year'(context, year) {
        context.commit('year', year);
        localStorage.setItem('year', year);
    },
    'set-month'(context, month) {
        context.commit('month', month);
        localStorage.setItem('month', month);
    }
};
 
const mutations = {
	employee(state, value) {
		state.employee = value
	},
    month(state, value) {
        state.month = value
    },
    year(state, value) {
        state.year = value
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}