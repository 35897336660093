<template id="calendarActivity">
    <span>
        <ProgressBar v-if="getCargando" mode="indeterminate" style="height: .5em; margin-top: -17px; width: 101%;" />

        <h4 class="text-black">REPORT TODOS COLABORADORES</h4>

        <div class="card ml-3 mr-3">
                <div class="field">
                    
                    <div class="field">
                        <Dropdown v-model="selectedContract" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                            <template #value="slotProps">
                                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                      <span>
                                        {{slotProps.value.name}}
                                      </span>
                                  </div>
                                  <span v-else>
                                      {{slotProps.placeholder}}
                                  </span>
                            </template>
                            <template #option="slotProps">
                                  <div class="p-dropdown-supervisors-option">
                                    <span>
                                          {{slotProps.option.name}}</span>
                                  </div>
                            </template>
                        </Dropdown>

                        <Dropdown v-model="selectedMonth" :options="monthNames" optionLabel="Nombre" :filter="false" placeholder="Seleccione Mes *" :showClear="true">
                            <template #value="slotProps">
                                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                      <span>
                                        {{slotProps.value.name}}
                                      </span>
                                  </div>
                                  <span v-else>
                                      {{slotProps.placeholder}}
                                  </span>
                            </template>
                            <template #option="slotProps">
                                  <div class="p-dropdown-supervisors-option">
                                    <span>
                                          {{slotProps.option.name}}</span>
                                  </div>
                            </template>
                        </Dropdown>
                        <Dropdown id="asscewfddsd" v-model="selectYear" :options="years" :filter="false" placeholder="Seleccione año *" :showClear="true">
                              <template #value="slotProps">
                                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                      <span>
                                        {{slotProps.value}}
                                      </span>
                                  </div>
                                  <span v-else>
                                      {{slotProps.placeholder}}
                                  </span>
                              </template>
                              <template #option="slotProps">
                              <div class="p-dropdown-supervisors-option">
                                <span>
                                      {{slotProps.option}}</span>
                              </div>
                            </template>
                        </Dropdown>
                        <buttonp class="w-8rem p-button-warning ml-2" icon="pi pi-search" label="BUSCAR" @click.prevent="getReport()"/>
                         
                    </div>
                </div>

        </div>

        
        
        <div v-if="colaboratorDetail.length > 0" >
            <div class="card ml-6 mr-6">

                <h5 class="text-black">{{colaboratorDetail.length}} Empleados Evaluados.</h5>
            </div>

                    <DataTable id="tableDetailColaborador" :value="colaboratorDetail" responsiveLayout="scroll" v-model:selection="selectedDetailFromReport" selectionMode="single" stickyHeader="true">
                        <Column  header="Colaborador">
                            <template #body="slotProps">
                                <span>{{slotProps.data.employee_name}}</span>
                            </template>
                        </Column>
                        <Column  header="Dias Evaluados">
                            <template #body="slotProps">
                                <span>{{slotProps.data.totals.dias_eval}}</span>
                            </template>
                        </Column>
                        <Column  header="Hrs Laborales">
                            <template #body="slotProps">
                                <span>{{slotProps.data.totals.h_laborales}}</span>
                            </template>
                        </Column>
                        <Column  header="Hrs NO Laborales">
                            <template #body="slotProps">
                                <span>{{slotProps.data.totals.h_no_laborales}}</span>
                            </template>
                        </Column>
                        <Column  header="Hrs Promedio Diarias">
                            <template #body="slotProps">
                                <span>{{slotProps.data.totals.h_prom_diario}}</span>
                            </template>
                        </Column>
                        <Column  header="Hrs Totales">
                            <template #body="slotProps">
                                <span>{{slotProps.data.totals.h_totales}}</span>
                            </template>
                        </Column>
            </DataTable>

            <br /><br /><br /><br /><br /><br />
            
        </div>
        <div v-else>
            <h2>Sin Resultados</h2>
        </div>
        

         <Dialog :header="'Detalle Colaborador'" v-model:visible="displayDetail" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '90vw'}">

                
                     <DataTable id="tableDetailColaborador" :value="selectedDetailFromReport.detail" responsiveLayout="scroll" selectionMode="single" stickyHeader="true">
                        <Column  header="Fecha">
                            <template #body="slotProps">
                                <span>{{slotProps.data.date}}</span>
                            </template>
                        </Column>
                        
                        <Column  header="Entrada">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.details[0].start_time == null">
                                    <Tag severity="danger" value="Sin fecha"></Tag>
                                </span>
                                <span v-else><Tag severity="success" :value="getOnlyTime(slotProps.data.details[0].start_time)"></Tag></span>
                            </template>
                        </Column>
                        <Column header="Salida">
                             <template #body="slotProps">
                                <span v-if="slotProps.data.details[0].end_time == null">
                                    <Tag severity="danger" value="Sin fecha"></Tag>
                                </span>
                                <span v-else><Tag severity="success" :value="getOnlyTime(slotProps.data.details[0].end_time)"></Tag></span>
                            </template>
                        </Column>
                        <Column  header="Contrato">
                            <template #body="slotProps">
                                <span>{{slotProps.data.details[0].contract.name}}</span>
                            </template>
                        </Column>
                        <Column header="Tipo Asistencia">
                             <template #body="slotProps">
                                 <span v-if="slotProps.data.details[0].attendance_type">
                                  {{slotProps.data.details[0].attendance_type.name}}
                                </span>
                                <span v-else><Tag severity="danger" value="Sin tipo"></Tag></span>
                            </template>
                        </Column>
                        <Column header="subtotal">
                             <template #body="slotProps">
                                 <span>{{calculateSubtotal(slotProps.data.details[0].start_time, slotProps.data.details[0].end_time)}}</span>
                            </template>
                        </Column>
                        <Column  header="Entrada">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.details[1].start_time == null">
                                    <Tag severity="danger" value="Sin fecha"></Tag>
                                </span>
                                <span v-else><Tag severity="success" :value="getOnlyTime(slotProps.data.details[1].start_time)"></Tag>
                                </span>
                            </template>
                        </Column>
                        <Column header="Salida">
                             <template #body="slotProps">
                                 <span v-if="slotProps.data.details[1].end_time == null">
                                    
                                    <Tag severity="danger" value="Sin fecha"></Tag>
                                </span>
                                <span v-else>
                                <Tag severity="success" :value="getOnlyTime(slotProps.data.details[1].end_time)"></Tag>
                            </span>
                            </template>
                        </Column>
                        <Column  header="Contrato">
                            <template #body="slotProps">
                                <span>{{slotProps.data.details[1].contract.name}}</span>
                            </template>
                        </Column>
                        <Column header="Tipo Asistencia">
                             <template #body="slotProps">
                                 <span v-if="slotProps.data.details[1].attendance_type">
                                  {{slotProps.data.details[1].attendance_type.name}}
                                </span>
                                <span v-else><Tag severity="danger" value="Sin tipo"></Tag></span>
                            </template>
                        </Column>
                        <Column header="Subtotal">
                             <template #body="slotProps">
                                <span>{{calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time)}}</span>
                            </template>
                        </Column>
                        <Column header="Subtotal">
                             <template #body="slotProps">
                                <span>{{ getTotalRow(calculateSubtotal(slotProps.data.details[0].start_time, slotProps.data.details[0].end_time), calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time))}}</span>
                            </template>
                        </Column>
            </DataTable>

            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-check" @click="displayDetail=false; selectedDetailFromReport = null;" autofocus />
            </template>
        </Dialog>

    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    //import EmployeeService from '@/service/EmployeeService';
    import AttendanceService from '@/service/AttendanceService';
    //import Statustag from '@/components/StatusTag'
    import moment from "moment";
    import ContractService from '@/service/ContractService'


    let CollaboratorMonthReport = {};
    (function () {
        this.name = 'collaborator-month-report';

        this.created = function () {
            this.getAllContracts();

            if(this.searchemployee == "[object Object]" || this.searchMonth == "[object Object]" || this.searchYear == "[object Object]"){
                this.selectedColaborator = null; 
                this.selectYear = null; 
                this.selectedMonth = null; 
            }else{
                if((this.searchemployee !== undefined || this.searchemployee !== null) && (this.searchYear !== undefined || this.searchYear !== undefined) && this.searchMonth !== undefined || this.searchMonth !== undefined){
                        this.selectedColaborator = this.searchemployee;
                        this.selectYear = this.searchYear;
                        this.selectedMonth = this.searchMonth;
                        this.getReport();
                }
            }
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                cargando: false,
                allStatus: [],
                contracts: [],
                selectedContract: null,
                colaboratorDetail: [],
                selectedDetails: [],
                displayDetailDocs: false,
                selectedColaborator: null,
                filteredDriversMultiple: [],
                all_colaboratrs: [],
                documentations: [],
                selectedDetailFromReport: null,
                monthNames: [{id:1, name: 'Enero'}, {id:2, name: 'Febrero'}, {id:3, name: 'Marzo'}, {id:4, name: 'Abril'}, {id:5, name: 'Mayo'}, {id:6, name: 'Junio'}, {id:7, name: 'Julio'}, {id:8, name: 'Agosto'}, {id:9, name: 'Septiembre'}, {id:10, name: 'Octubre'}, {id:11, name: 'Noviembre'}, {id:12, name: 'Deciembre'}],
                selectedMonth: null,
                years:[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027],
                selectYear: null,
                noEncontro: false,
                displayDetail: false,
                selectedDetail: null,
            }
        };
        this.methods = {
            getOnlyTime(date){
              
              if(date){
                 var stillUtc = moment.utc(date,"YYYY-MM-DD\THH:mm:ss\Z").subtract(4, "hours").format('HH:mm');
                //var stillUtc = moment.utc(date).toDate();
              
                //var local = moment(stillUtc).local().format('HH:mm');
                return stillUtc;
              }
          },
            getTotalRow(val,val2){
                return parseFloat(val)+parseFloat(val2);
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY, h:mm:ss');
                  return local;
                }
            },
            calculateSubtotal(date1, date2){
                if(date1 === null || date2 === null)
                    return 0
                else{
                    var stillUtc = moment.utc(date1);
                    var stillUtc_2 = moment.utc(date2);
                    var duration = stillUtc_2.diff(stillUtc);
                    if(duration > 0){
                        duration = Math.floor(duration / 1000);
                        duration= duration * 0.000277778;
                        duration = duration.toFixed(2)
                    }
                    return duration;
                }
            },
            proccessmessageResponse(response){
                var isError = true
                if(response.data.status == 'OK') 
                    isError = false;
                this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
            },

            getAllContracts(){
                this.$store.dispatch('form/submit', true);
                ContractService.getByEmployee(this.username).then((response) => {
                    this.contracts = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },

            validate(){
               let result = {isError: false, message: null}
                if(this.selectedContract == null){
                    result.isError = true;
                    result.message = 'Seleccione Contrato';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                if(this.selectYear == null){
                    result.isError = true;
                    result.message = 'Seleccione año';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                if(this.selectedMonth == null){
                    result.isError = true;
                    result.message = 'Seleccione mes';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                return true;
            },
            getReport() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}
                this.noEncontro = false;
                if(!this.validate()) return;

                this.$store.dispatch('search/set-month', this.selectedMonth);
                this.$store.dispatch('search/set-year', this.selectYear);
                this.colaboratorDetail = [];
                
                this.cargando = true;
                AttendanceService.getAllMonthReport(this.selectYear, this.selectedMonth.id, this.selectedContract.contract_id)
                    .then(response => {
                    
                    this.colaboratorDetail = response.data.data;
                    this.$store.dispatch('form/submit', false);
                    this.cargando = false;
                    })
                    .catch(err => {
                      console.log(err);
                      this.cargando = false;
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.components = {};
        this.computed = {
            getNoEncontro(){
                return this.noEncontro;
            },
            getCargando(){
                return this.cargando;
            },
            ...mapGetters({
                username: 'auth/user',
                searchemployee: 'search/employee',
                searchYear: 'search/year',
                searchMonth: 'search/month',
                groups: 'auth/groups'
            }),
        };
        this.watch = {
            selectedDetailFromReport(newvalue){
                if (newvalue != null) {

                    this.displayDetail = true;
                }
            }
        };
        this.props = [];
    }).apply(CollaboratorMonthReport);

    export default CollaboratorMonthReport
</script>
