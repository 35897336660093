<template>
    <nav class="navbar is-fixed-bottom" role="navigation" aria-label="main navigation" style="position:fixed;">
        <div class="has-text-centered">
            <div class="grid">
                <div class="col-2">
                    <span v-if="isDisablingBottom === false">
                        <buttonp class="p-button-secondary" icon="pi pi-arrow-left" v-if="$route.name !== 'home'" @click.prevent="goBack()"/>
                    </span>
                    <span v-else>
                        <buttonp class="p-button-secondary" icon="pi pi-arrow-left" v-if="$route.name !== 'home'" @click.prevent="goBack()" disabled/>
                    </span>
                </div>
                <div class="col-8">
                    <buttonp class="p-button-secondary" v-if="$route.name !== 'home'" icon="pi pi-home" @click.prevent="goTo('home')"/>
                    <div>
                    &copy;{{ new Date().getFullYear() }} - <strong>Transportes Ventanas</strong>
                    </div>
               </div>
                <div class="col-2">
                    <buttonp class="p-button-secondary" icon="pi pi-arrow-up" v-if="$route.name !== 'home'" @click.prevent="scrollUp()"/>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "toolbar",

        data () {
                return {
                    disablingButton: false
                }
            },


        computed:{
                isDisablingBottom(){
                    return this.disablingButton;
                }
            },


        methods: {

            goBack() {
                var b = this;
                b.disablingButton = true;
                window.setTimeout(
                    function() {b.disablingButton = false;
                }, 2000);
                this.$router.go(-1)
            },
            goTo (route) {
                this.$router.push({name: route});
            },
            scrollUp () {
               window.scrollTo(0, 0); 
            }
        }
    }
</script>

<style scoped>
    .navbar > div {
        padding: 1.2rem 0 1.2rem 0;
        height: auto;
        background-color: black;
        color: white;
        opacity: 1;
        width: 100%;
        position: fixed; 
        bottom: 0;
        left: 0;
        right: 0;

        strong {
            color: white;
        }
    }
</style>
