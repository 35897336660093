<template id="home">
    <div>
        <p>Consulta</p>
        <div class="card">
            <div class="flex justify-content-center flex-wrap card-container yellow-container">
                <div class="flex align-items-center justify-content-center w-12rem h-5rem bg-yellow-500 font-bold text-gray-900 border-round m-2">
                    <buttonp class="w-6 mt-3 p-button-warning" label="Por Persona" @click.prevent="goTo('collaborator-report')"/>
                </div>
                <div class="flex align-items-center justify-content-center w-12rem h-5rem bg-yellow-500 font-bold text-gray-900 border-round m-2">
                    <buttonp class="w-6 mt-3 p-button-warning" label="TODOS MENSUAL" @click.prevent="goTo('all-collaborator-report')"/>
                </div>
            </div>
        </div>

        <p>Gestion</p>
         <div class="card">
            <div class="flex justify-content-center flex-wrap card-container yellow-container">
                <div class="flex align-items-center justify-content-center w-12rem h-5rem bg-yellow-500 font-bold text-gray-900 border-round m-2">
                    <buttonp class="w-6 mt-3 p-button-warning" label="Gestion General" @click.prevent="goTo('admin-collaborator')"/>
                </div>
            </div>
        </div>

        <br /><br />
    </div>
</template>


<script>

    import {mapGetters} from 'vuex';
    
    let HomePage = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;

        this.created = function () {

        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               
            }
        };
        this.methods = {
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        

        this.props = [];
    }).apply(HomePage);

    export default HomePage
</script>
