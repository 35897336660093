<template id="calendarActivity">
    <span>
        <ProgressBar v-if="getCargando" mode="indeterminate" style="height: .5em; margin-top: -17px; width: 101%;" />

        <h4 class="text-black">REPORT MENSUAL COLABORADOR</h4>

        <div class="card ml-3 mr-3">
                <div class="field">
                    <label for="contractdropdown" class="p-sr-only">Colaborador</label>
                    <div class="field">
                        <autoComplete v-model="selectedColaborator" field="first_name1" :suggestions="filteredDriversMultiple" @complete="searchDriversMultiple($event)" placeholder="Busque conductor"></autoComplete>

                        <Dropdown v-model="selectedMonth" :options="monthNames" optionLabel="Nombre" :filter="false" placeholder="Seleccione Mes *" :showClear="true">
                            <template #value="slotProps">
                                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                      <span>
                                        {{slotProps.value.name}}
                                      </span>
                                  </div>
                                  <span v-else>
                                      {{slotProps.placeholder}}
                                  </span>
                            </template>
                            <template #option="slotProps">
                                  <div class="p-dropdown-supervisors-option">
                                    <span>
                                          {{slotProps.option.name}}</span>
                                  </div>
                            </template>
                        </Dropdown>
                        <Dropdown id="asscewfddsd" v-model="selectYear" :options="years" :filter="false" placeholder="Seleccione año *" :showClear="true">
                              <template #value="slotProps">
                                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                      <span>
                                        {{slotProps.value}}
                                      </span>
                                  </div>
                                  <span v-else>
                                      {{slotProps.placeholder}}
                                  </span>
                              </template>
                              <template #option="slotProps">
                              <div class="p-dropdown-supervisors-option">
                                <span>
                                      {{slotProps.option}}</span>
                              </div>
                            </template>
                        </Dropdown>
                        <buttonp class="w-8rem p-button-warning ml-2" icon="pi pi-search" label="BUSCAR" @click.prevent="getReport()"/>
                         
                    </div>
                </div>
               
                <!--h4 v-if="colaboratorDetail.length > 0" class="text-black">Totales documentos evaluados: {{total_report.total_eval}}</h4-->
        </div>

        
        
        <div v-if="colaboratorDetail.length > 0" >
            <div class="card ml-6 mr-6">

                <h4 class="text-black">TOTALES <span v-if="selectedColaborator.first_name1">{{selectedColaborator.first_name1}}</span> 
                </h4>

                <h5 class="text-black">{{colaboratorDetail.length}} Dias Evaluados.</h5>
            </div>
            <ConfirmDialog></ConfirmDialog>
                    <DataTable id="tableDetailColaborador" :value="colaboratorDetail" responsiveLayout="scroll" v-model:selection="selectedDetailFromReport" selectionMode="single" stickyHeader="true">
                        <Column  header="Fecha" :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.date}}</span>
                            </template>
                        </Column>
                        
                        <Column  header="Entrada" :sortable="true">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.details[0].start_time == null">
                                    Sin fecha
                                </span>
                                <span>{{getLocalDate(slotProps.data.details[0].start_time)}}</span>
                            </template>
                        </Column>
                        <Column header="Salida" :sortable="true">
                             <template #body="slotProps">
                                <span v-if="slotProps.data.details[0].end_time == null">
                                    Sin fecha
                                </span>
                                <span>{{getLocalDate(slotProps.data.details[0].end_time)}}</span>
                            </template>
                        </Column>
                        <Column  header="Contrato" :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.details[0].contract.name}}</span>
                            </template>
                        </Column>
                        <Column header="subtotal" :sortable="true">
                             <template #body="slotProps">
                                 <span>{{calculateSubtotal(slotProps.data.details[0].start_time, slotProps.data.details[0].end_time)}}</span>
                            </template>
                        </Column>
                        <Column  header="Entrada" :sortable="true">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.details[1].start_time == null">
                                    Sin fecha
                                </span>
                                <span >{{getLocalDate(slotProps.data.details[1].start_time)}}</span>
                            </template>
                        </Column>
                        <Column header="Salida" :sortable="true">
                             <template #body="slotProps">
                                 <span v-if="slotProps.data.details[1].end_time == null">
                                    Sin fecha
                                </span>
                                <span>{{getLocalDate(slotProps.data.details[1].end_time)}}</span>
                            </template>
                        </Column>
                        <Column  header="Contrato" :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.details[1].contract.name}}</span>
                            </template>
                        </Column>
                        <Column header="Subtotal" :sortable="true">
                             <template #body="slotProps">
                                <span>{{calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time)}}</span>
                            </template>
                        </Column>
                        <Column header="Subtotal" :sortable="true">
                             <template #body="slotProps">
                                <span>{{ getTotalRow(calculateSubtotal(slotProps.data.details[0].start_time, slotProps.data.details[0].end_time), calculateSubtotal(slotProps.data.details[1].start_time, slotProps.data.details[1].end_time))}}</span>
                            </template>
                        </Column>
            </DataTable>

            <br /><br /><br /><br /><br /><br />
            
        </div>
        <div v-else>
            <h2>Sin Resultados</h2>
        </div>

        <Dialog :header="'Edicion detalle'" v-model:visible="displayDetail" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">

                <div class="md-min-width card m-5">
                    <h5 class="text-black">Comienzo</h5>
                    <Calendar  id="expiredIdCalendar" v-model="selectedDetail.start_time" :showTime="true" dateFormat="dd/mm/yy" />
                    <h5 class="text-black">Termino</h5>
                    <Calendar id="expiredIdCalendar" v-model="selectedDetail.end_time" :showTime="true" dateFormat="dd/mm/yy" />
                    <br /><br />
                    <Dropdown v-model="selectedDetail.contract" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true">
                        <template #value="slotProps">
                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                  <span>
                                    {{slotProps.value.name}}
                                  </span>
                              </div>
                              <span v-else>
                                  {{slotProps.placeholder}}
                              </span>
                        </template>
                        <template #option="slotProps">
                              <div class="p-dropdown-supervisors-option">
                                <span>
                                      {{slotProps.option.name}}</span>
                              </div>
                        </template>
                    </Dropdown>
                </div>

            <template #footer>
                <buttonp label="Guardar" class="ml-1 p-button-sm" icon="pi pi-pencil"  @click="saveEditDetail()" autofocus/>
                <buttonp label="Cerrar" icon="pi pi-check" @click="displayDetail=false;" autofocus />
            </template>
        </Dialog>
        
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import EmployeeService from '@/service/EmployeeService';
    import AttendanceService from '@/service/AttendanceService';
    //import Statustag from '@/components/StatusTag'
    import moment from "moment";
    import ContractService from '@/service/ContractService'


    let CollaboratorMonthReport = {};
    (function () {
        this.name = 'collaborator-month-report';

        this.created = function () {
            this.getAllColaborator();
            //this.getAllDocumentation();
            //this.getAllStatus();
            this.getAllContracts();

            if(this.searchemployee == "[object Object]" || this.searchMonth == "[object Object]" || this.searchYear == "[object Object]"){
                this.selectedColaborator = null; 
                this.selectYear = null; 
                this.selectedMonth = null; 
            }else{
                if((this.searchemployee !== undefined || this.searchemployee !== null) && (this.searchYear !== undefined || this.searchYear !== undefined) && this.searchMonth !== undefined || this.searchMonth !== undefined){
                        this.selectedColaborator = this.searchemployee;
                        this.selectYear = this.searchYear;
                        this.selectedMonth = this.searchMonth;
                        this.getReport();
                }
            }
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                cargando: false,
                allStatus: [],
                contracts: [],
                selectedContract: null,
                colaboratorDetail: [],
                total_report: [],
                selectedDetails: [],
                displayDetailDocs: false,
                selectedColaborator: null,
                filteredDriversMultiple: [],
                all_colaboratrs: [],
                documentations: [],
                selectedDetailFromReport: null,
                monthNames: [{id:1, name: 'Enero'}, {id:2, name: 'Febrero'}, {id:3, name: 'Marzo'}, {id:4, name: 'Abril'}, {id:5, name: 'Mayo'}, {id:6, name: 'Junio'}, {id:7, name: 'Julio'}, {id:8, name: 'Agosto'}, {id:9, name: 'Septiembre'}, {id:10, name: 'Octubre'}, {id:11, name: 'Noviembre'}, {id:12, name: 'Deciembre'}],
                selectedMonth: null,
                years:[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027],
                selectYear: null,
                noEncontro: false,
                displayDetail: false,
                selectedDetail: null,
            }
        };
        this.methods = {
            getTotalRow(val,val2){
                return parseFloat(val)+parseFloat(val2);
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY, h:mm:ss');
                  return local;
                }
            },
            calculateSubtotal(date1, date2){
                if(date1 === null || date2 === null)
                    return 0
                else{
                    var stillUtc = moment.utc(date1);
                    var stillUtc_2 = moment.utc(date2);
                    var duration = stillUtc_2.diff(stillUtc);
                    if(duration > 0){
                        duration = Math.floor(duration / 1000);
                        duration= duration * 0.000277778;
                        duration = duration.toFixed(2)
                    }
                    return duration;
                }
            },
            editDetail(detail){
                this.selectedDetail=detail;
                this.displayDetail= true;
            },
            saveEditDetail(){
                 AttendanceService.editDetail(this.selectedDetail).then((response) => {
                    this.proccessmessageResponse(response);
                    if(response.status =='OK')
                        this.displayDetail = false;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            proccessmessageResponse(response){
                var isError = true
                if(response.data.status == 'OK') 
                    isError = false;
                this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
            },
            addAllAttendanceMonth(){
                this.$store.dispatch('form/submit', true)

                if(!this.validate()) return;

                if(this.selectedContract == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Contrato'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }
                AttendanceService.addAllMonth(this.selectYear, this.selectedMonth.id, this.selectedColaborator.employee_id, this.selectedContract.contract_id).then((response) => {
                    this.proccessmessageResponse(response);
                    this.colaboratorDetail = response.data.data.detail;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },
            addEmptyAttendance(){
                this.$store.dispatch('form/submit', true)
                if(this.selectedContract == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Contrato'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }
                if(!this.validate()) return;
                AttendanceService.addAttendance(this.selectYear, this.selectedMonth.id, this.selectedColaborator.employee_id, this.selectedContract.contract_id).then((response) => {
                    this.proccessmessageResponse(response);
                    this.colaboratorDetail = response.data.data.detail;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            addAttendance(){
                this.$store.dispatch('form/submit', true)
                if(this.selectedContract == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Contrato'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }
                if(!this.validate()) return;
                AttendanceService.addOnePage(this.selectYear, this.selectedMonth.id, this.selectedColaborator.employee_id, this.selectedContract.contract_id).then((response) => {
                    this.proccessmessageResponse(response);
                    this.colaboratorDetail = response.data.data.detail;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            getAllStatus(){
                this.$store.dispatch('form/submit', true);
            },
            deleteAttendance(attandence) {
                this.$confirm.require({
                    message: '¿Desea borrar detalle?',
                    header: 'Eliminar detalle pagina de asistencia',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        //let result = {isError: false, message: null}
                        this.$store.dispatch('form/reset');
                        this.$store.dispatch('form/submit', true);
                        AttendanceService.deleteDetail(attandence.attendance_id).then((response) => {
                            this.proccessmessageResponse(response);
                            this.$store.dispatch('form/submit', false);
                            this.getReport();
                        }).catch((error) => {
                            console.log(error);
                            this.$store.dispatch('form/submit', false);
                        });
                    },
                    reject: () => {
                        
                    }
                });
            },
            getAllContracts(){
                this.$store.dispatch('form/submit', true);
                ContractService.getByEmployee(this.username).then((response) => {
                    this.contracts = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllColaborator(){
                 EmployeeService.getAll().then((response) => {
                        this.all_colaboratrs= response.data;
                        for (var i = 0; i < this.all_colaboratrs.length; i++) {
                            this.all_colaboratrs[i]['first_name1'] = this.all_colaboratrs[i]['first_name1'] +" "+this.all_colaboratrs[i]['first_name2']+' '+this.all_colaboratrs[i]['last_name1'] + ' ' +this.all_colaboratrs[i]['last_name2']+'('+this.all_colaboratrs[i]['card_id'] +'-'+ this.all_colaboratrs[i]['check_digit']+')';
                        }

                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            searchDriversMultiple(event){
                var filteredResultDrivers = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_colaboratrs.length; i++) {
                        if (this.all_colaboratrs[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.all_colaboratrs[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResultDrivers.push(this.all_colaboratrs[i])
                        }
                    }
                    this.filteredDriversMultiple = filteredResultDrivers;
                } else {
                    return [];
                }   
            },
            openDetailDocumentation(id){
                this.$router.push({name: '',
                           params: {
                                documentation_implementation_id: id
                            }
                       });
            },
            validate(){
               let result = {isError: false, message: null}
                if(this.selectedColaborator == null){
                    result.isError = true;
                    result.message = 'Seleccione Colaborador';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                if(this.selectYear == null){
                    result.isError = true;
                    result.message = 'Seleccione año';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                if(this.selectedMonth == null){
                    result.isError = true;
                    result.message = 'Seleccione mes';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return false;
                }
                return true;
            },
            getReport() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}
                this.noEncontro = false;
                if(!this.validate()) return;

                this.$store.dispatch('search/set-employee', this.selectedColaborator);
                this.$store.dispatch('search/set-month', this.selectedMonth);
                this.$store.dispatch('search/set-year', this.selectYear);
                this.colaboratorDetail = [];
                this.total_report = [];
                
                this.cargando = true;
                AttendanceService.getMonthReport(this.selectYear, this.selectedMonth.id, this.selectedColaborator.employee_id)
                    .then(response => {
                    
                    this.colaboratorDetail = response.data.data.detail;

                    console.log(this.colaboratorDetail)
                    this.total_report = 0;
                          
                    if(response.data.data.detail.length === 0){
                        this.noEncontro = true;
                        result.isError = true;
                        result.message = 'Sin resultados';
                        this.$store.dispatch('form/result', result);
                    }
                    this.$store.dispatch('form/submit', false);
                    this.cargando = false;
                    })
                    .catch(err => {
                      console.log(err);
                      this.cargando = false;
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.components = {};
        this.computed = {
            getNoEncontro(){
                return this.noEncontro;
            },
            getCargando(){
                return this.cargando;
            },
            ...mapGetters({
                username: 'auth/user',
                searchemployee: 'search/employee',
                searchYear: 'search/year',
                searchMonth: 'search/month',
                groups: 'auth/groups'
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(CollaboratorMonthReport);

    export default CollaboratorMonthReport
</script>
